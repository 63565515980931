.footer{
    padding-top: 70px;
    padding-bottom: 30px;
}

.footer .logo{
    width: auto;
}

.footer .logo img{
    width: 50%;
    margin-bottom: 1rem;
}

.footer .logo p{
    color: var(--text-color);
}

.social__links span a{
    text-decoration: none;
    color: var(--heading-color);
    font-size: 1.2rem;
}

.footer__link-title{
    color: var(--heading-color);
    margin-bottom: 1rem;
}

.footer__quick-links li a{
    text-decoration: none;
    color: var(--text-color);
    font-size: 1.1rem;
}

.footer__quick-links h6 span i{
    color: var(--secondary-color);
    font-size: 1.1rem;
}

.copyright{
    color: var(--text-color);
    font-size: 1rem;
}

@media only screen and (max-width:992px) {
    .footer .logo img {
        width: 15%;
    }
}

@media only screen and (max-width:767px) {
   .footer .logo img {
        width: 35%;
    }
}