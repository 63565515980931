.thank__you span i{
    font-size: 4rem;
    color: orange;
}

.thank__you h1{
    font-size: 3.6rem;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

.thank__you h3{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

.soon{
    background-color: black;
    position: absolute;
    left: .02%;
    top:  .02%;;
    width: 100%;
    height: 100%;
    align-content: center;
}

.image{
    width: 48%;
}

@media only screen and (max-width:630px){
    .image{
        width: 100%;
    }
}